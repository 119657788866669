import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { HomeComponent } from '../home';
import { AboutComponent } from '../about';
import { ServicesComponent } from '../services';
import { AftercaresComponent } from '../aftercares';
import { PortfolioComponent } from '../portfolio';
import { GalleryComponent } from '../gallery';
import { ProjectComponent } from '../project';
import { ContactComponent } from '../contact';
import { FooterComponent } from '../footer';
import { FooterGrayComponent } from '../footerGray';
import { FooterPageComponent } from '../footerPage';
import { FooterPortfolio } from '../footerPortfolio';
import { FooterContactComponent } from '../footerContact';

const MainComponent = () => {
  return (

    <Router>
      <Routes>

        <Route path="/" element={<>
          <HomeComponent />
          <FooterComponent />
        </>} />
        
        <Route path="/about" element={<>
          <AboutComponent />
          <FooterPageComponent />
        </>} />

        <Route path="/services" element={<>
          <ServicesComponent />
          <FooterComponent />
        </>} />

        <Route path="/aftercare" element={<>
          <AftercaresComponent />
          <FooterComponent />
        </>} />

        <Route path="/portfolio" element={<>
          <PortfolioComponent />
          <FooterPageComponent />
        </>} />

        <Route path="/gallery" element={<>
          <GalleryComponent />
          <FooterPageComponent />
        </>} />
        
        <Route path="/:slug" element={<>
          <ProjectComponent />
          <FooterPortfolio />
        </>} />

        <Route path="/contact" element={<>
          <ContactComponent />
          <FooterContactComponent />
        </>} />

      </Routes>
    </Router>
  );
}

export default MainComponent;