import React from 'react';
import { useCookies } from 'react-cookie';
import { useEffect, useState } from "react";
import LayoutComponent from "./components/layout/layout.component";
import { DarkModeSwitch } from 'react-toggle-dark-mode';

function App() {
  const style = document.getElementById('style-file') as HTMLLinkElement;
  const [isDarkMode, setDarkMode] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['theme']);

  const toggleDarkMode = (checked: boolean) => {
      if (isDarkMode == true){
          style.href = '/css/dark.css';
          setCookie("theme", "light")
      }
      else{
          style.href = '/css/light.css';
          setCookie("theme", "dark")
      }
      
      setDarkMode(checked);
    };

    useEffect(() => {
      if (cookies.theme=="dark"){
        style.href = '/css/light.css';
        setDarkMode(true);
      }
      if (cookies.theme=="light"){
        style.href = '/css/dark.css'
        setDarkMode(false)
      }
      else{
        style.href = '/css/light.css';
        setDarkMode(true);
      }
  }, []);
  return (<>
  
    <DarkModeSwitch
      style={{  position:'absolute', left:"3vw", bottom:"3vw", zIndex:"1001" }}
      checked={isDarkMode}
      onChange={toggleDarkMode}
      moonColor="black"
      sunColor="white"
      size={40}
    />
    <LayoutComponent />
    </>
  );
}



export default App;
