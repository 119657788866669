
import useScript from "../../hooks/useScript"
import { useEffect, useState } from "react";
import { request, gql } from 'graphql-request'
import ReactHtmlParser from 'react-html-parser';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import ReactPaginate from 'react-paginate';

const query = gql`
{
  pageGalleries {
    description
    pictures {
      url(
      transformation: {
        image: { resize: { width: 1000, fit: clip } }
      }
     )
    }
    title
  }
}`

const GalleryComponent = () => {

  const [gallery, setGallery] = useState<any[]>([]);
  const [pictures, setPictures] = useState<any[]>([]);
  const [picturesLoaded, setPicturesLoaded] = useState<any[]>([]);
  const [loaded, setLoaded] = useState(Boolean);

  useEffect(() => {
    async function getData() {
      const res = await request('https://api-eu-west-2.hygraph.com/v2/clcsvyi131soh01ur2dq19b6b/master', query);
      setGallery(res.pageGalleries);
      setPictures(res.pageGalleries[0].pictures)
      setLoaded(true);
    }
    getData();
  }, []);

  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 15;

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const pageCount = Math.ceil(pictures.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % pictures.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };


  return (

    <>
      <div id="portfolio-1" className="container-fluid container-section relative container-section-center page-margin-top">
        <div id="portfolio-1-scroll" className="row fit-content row-no-margin block">
          <div id="portfolio-title" className="row row-no-margin padding-left-inside">
            <h2 id="portfolio-title" className="section-title">
              <span className="section-maintitle"></span>
              <span className="section-subtitle"><a href="#">{
                loaded ? ReactHtmlParser(gallery[0].title) : ""
              }</a></span>
            </h2>
          </div>
          <div id="portfolio-subtitle" className="row row-no-margin padding-left-inside">
            <div className="section-description">
              <p>{
                loaded ? ReactHtmlParser(gallery[0].description) : ""
              }</p>
            </div>
          </div>
          <div className="row row-no-margin">
            <div className="projects-grid gallery-grid">
              <ResponsiveMasonry
                columnsCountBreakPoints={{ 800: 1, 1200: 2, 1600: 3 }}
              >
                <Masonry gutter="10px">
                  {
                    loaded ? pictures.map((picture: any, i: any) => {
                      if (i >= itemOffset && i < itemOffset+itemsPerPage){
                        return (
                          <img
                            key={i}
                            src={picture.url}
                            style={{ width: "100%", display: "block" }}
                            alt=""
                          />
                        );
                      }
                    }) : ""
                  }
                </Masonry>
              </ResponsiveMasonry>
              <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="< previous"
              />
            </div>
          </div>


        </div>
      </div>

    </>
  );
}

export default GalleryComponent;