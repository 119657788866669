import { useEffect, useState, useRef } from "react";
import { request, gql } from 'graphql-request'
import ReactHtmlParser from 'react-html-parser';
import emailjs from 'emailjs-com';


const query = gql`
  {
  pageContacts {
    emailTitle
    title
    contactText
  }
  companyInfos {
    phoneNumber
    companyName
    companyAddress
    emailAddress
  }
}`



const ContactComponent = () => {

  const [contactPage, setContactPage] = useState<any[]>([]);
  const [contactInfo, setContact] = useState<any[]>([]);
  const [loaded, setLoaded] = useState(Boolean);
  const form = useRef<null | HTMLFormElement>(null);

  const box = document.getElementById('contact-success');
  const contact_form = document.getElementById('contact-form');

  const sendEmail = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    if (form.current == null) return;
    emailjs.sendForm('service_n5025ek', 'template_gyclr7l', form.current, 'rmx-PhXQdYnpL6Wui')
      .then((result) => {
        if (box != null) {
          setTimeout(() => {
            box.classList.add('contact-success-show');
          },
            1000);
        }
        if (contact_form != null) {
          contact_form.classList.add('hide');
          setTimeout(() => {
            contact_form.style.display = "none";
          },
            1000);
        }
        console.log(result.text);

      }, (error) => {
        console.log(error.text);
      });
  };

  useEffect(() => {
    async function getData() {
      const res = await request('https://api-eu-west-2.hygraph.com/v2/clcsvyi131soh01ur2dq19b6b/master', query);
      setContactPage(res.pageContacts);
      setContact(res.companyInfos);
      setLoaded(true);
      console.log(contactInfo[0].phoneNumber)
    }
    getData();
  }, []);


  return (
    <>
      <div id="contact-1"
        className="container-fluid container-section relative container-section page-margin-top padding-page">
        <div id="contact-1-scroll" className="row fit-content row-no-margin block">
          <div className="row row-no-margin">
            <h2 className="section-title">
              <span className="section-maintitle">
                {
                  loaded ? ReactHtmlParser(contactPage[0].title) : ""
                }</span>
            </h2>
          </div>
          <div className="row row-no-margin">
            <div className="section-description contact-description" >
              <p>
                {
                  loaded ? ReactHtmlParser(contactPage[0].contactText) : ""
                }
              </p>
            </div>
          </div>
          <div className="row row-no-margin" >
            <div className="section-description contact-info">
              <div className="contact-info-item">
                <div className="contact-info-title">E-mail</div>
                <div className="contact-info-value">{
                  loaded ? ReactHtmlParser(contactInfo[0].emailAddress) : ""
                }</div>
              </div>
              <div className="contact-info-item">
                <div className="contact-info-title">Phone No.</div>
                <div className="contact-info-value">{
                  loaded ? ReactHtmlParser(contactInfo[0].phoneNumber) : ""
                }</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="contact-2"
        className="container-fluid container-section relative container-section contact-margin-top padding-page">
        <div id="contact-2-scroll" className="row fit-content row-no-margin block">
          <div className="row row-no-margin">
            <h2 className="section-title">
              <span className="section-maintitle">
                {
                  loaded ? ReactHtmlParser(contactPage[0].emailTitle) : ""
                }</span>
            </h2>
          </div>
          <div className="row row-no-margin">
            <div className="section-description contact-form-container">
              <div id="contact-success" className="contact-success">
                Success!
              </div>
              <form id="contact-form" className="contact-form row row-no-margin" ref={form} onSubmit={sendEmail}>
                <div className="form-field">
                  <input id="name" name="from_name" className="input-text js-input" type="text" placeholder="Name" required />
                </div>
                <div className="form-field">
                  <input id="email" name="reply_to" className="input-text js-input" type="email" placeholder="E-mail" required />
                </div>
                <div className="form-field ">
                  <input id="name" name="subject" className="input-text js-input" type="text" placeholder="Subject" required />
                </div>
                <div className="form-field ">
                  <input id="email" name="company" className="input-text js-input" type="text" placeholder="Company" required />
                </div>
                <div className="form-field long-field">
                  <textarea id="message" name="message" className="input-text js-input" placeholder="Message" required
                    rows={5}></textarea>
                </div>
                <div className="form-field align-center">
                  <a href="#"><input className="submit-btn" type="submit" value="Submit" /></a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div id="contact-3" className="container-fluid container-section relative container-section padding-page padding-background">
        <div id="contact-3-scroll" className="row fit-content row-no-margin block">
          <div className="row row-no-margin">

          </div>
          <div className="row row-no-margin">

            <div className="section-description page-normal-text no-bottom-margin">
              <h2 className="section-title">
                <span className="section-maintitle">Location</span>
              </h2>
              <p>Nova Design Associates Ltd<br /> Unit 18, Mitre Bridge Ind. Park, Mitre Way <br />London W10 6AU | UK
              </p>
            </div>
            <div className="section-description page-normal-text no-bottom-margin">
              <iframe className="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9929.659260284216!2d-0.2310143!3d51.5239511!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xae1b610c2b438bb9!2sNova%20Designs!5e0!3m2!1sen!2spl!4v1671102483744!5m2!1sen!2spl" loading="lazy" ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactComponent;