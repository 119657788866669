
import useScript from "../../hooks/useScript"
import { useEffect, useState } from "react";
import { request, gql } from 'graphql-request'
import ReactHtmlParser from 'react-html-parser';

const query = gql`
  {
  pagePortfolios {
    title
    description
    projects {
      mainPicture {
        url(
      transformation: {
        image: { resize: { width: 1000, fit: clip } }
      }
     )
      }
      slug
      name
      year
    }
  }
}`

const PortfolioComponent = () => {

  const [portfolio, setPortfolio] = useState<any[]>([]);
  const [loaded, setLoaded] = useState(Boolean);

  useEffect(() => {
    async function getData() {
      const res = await request('https://api-eu-west-2.hygraph.com/v2/clcsvyi131soh01ur2dq19b6b/master', query);
      setPortfolio(res.pagePortfolios);
      setLoaded(true);
    }
    getData();
  }, []);


  return (

    <>
      <div id="portfolio-1" className="container-fluid container-section relative container-section-center page-margin-top">
        <div id="portfolio-1-scroll" className="row fit-content row-no-margin block">
          <div id="portfolio-title" className="row row-no-margin padding-left-inside">
            <h2 id="portfolio-title" className="section-title">
              <span className="section-maintitle"></span>
              <span className="section-subtitle"><a href="#">{
                loaded ? ReactHtmlParser(portfolio[0].title) : ""
              }</a></span>
            </h2>
          </div>
          <div id="portfolio-subtitle" className="row row-no-margin padding-left-inside">
            <div className="section-description">
              <p>{
                loaded ? ReactHtmlParser(portfolio[0].description) : ""
              }</p>
            </div>
          </div>
          <div className="row row-no-margin">
            <div className="projects-grid">
              {
                loaded ? portfolio[0].projects.filter((project: any, index: any) => index < 4).map((project: any, index: any) => {
                  if (index % 2 == 1) {
                    return (
                      <div data-size="small" className="project-item">
                        <div className="project-image">
                          <img src={project.mainPicture[0].url} alt="" />
                        </div>
                        <a href={project.slug}>
                          <div className="project-overlay"></div>
                        </a>
                        <div className="project-info">
                          <span className="project-name">{project.name}</span>
                          <span className="project-date">{project.year}</span>
                        </div>
                      </div>
                    );
                  }
                  return (

                    <div data-size="large" className="project-item">
                      <div className="project-image">
                        <img src={project.mainPicture[0].url} alt="" />
                      </div>
                      <a href={project.slug}>
                        <div className="project-overlay"></div>
                      </a>
                      <div className="project-info">
                        <span className="project-name">{project.name}</span>
                        <span className="project-date">{project.year}</span>
                      </div>
                    </div>
                  );
                }) : ""
              }
            </div>
          </div>


        </div>
      </div>

      <div id="portfolio-2" className="container-fluid container-section relative container-section-center background-color portfolio-container">
        <div id="portfolio-2-scroll" className="row fit-content row-no-margin block">
          <div className="row row-no-margin">
            <div className="projects-grid">
              {
                loaded ? portfolio[0].projects.filter((project: any, index: any) => index > 3 && index < 8).map((project: any, index: any) => {
                  if (index % 2 == 1) {
                    return (
                      <div data-size="small" className="project-item">
                        <div className="project-image">
                          <img src={project.mainPicture[0].url} alt="" />
                        </div>
                        <a href={project.slug}>
                          <div className="project-overlay"></div>
                        </a>
                        <div className="project-info">
                          <span className="project-name">{project.name}</span>
                          <span className="project-date">{project.year}</span>
                        </div>
                      </div>
                    );
                  }
                  return (

                    <div data-size="large" className="project-item">
                      <div className="project-image">
                        <img src={project.mainPicture[0].url} alt="" />
                      </div>
                      <a href={project.slug}>
                        <div className="project-overlay"></div>
                      </a>
                      <div className="project-info">
                        <span className="project-name">{project.name}</span>
                        <span className="project-date">{project.year}</span>
                      </div>
                    </div>
                  );
                }) : ""
              }
            </div>
          </div>


        </div>
      </div>

      <div id="portfolio-3" className="container-fluid container-section relative container-section-center portfolio-container background-color">
        <div id="portfolio-3-scroll" className="row fit-content row-no-margin block">
          <div className="row row-no-margin">
            <div className="projects-grid">
              {
                loaded ? portfolio[0].projects.filter((project: any, index: any) => index > 7 && index < 12).map((project: any, index: any) => {
                  if (index % 2 == 1) {
                    return (
                      <div data-size="small" className="project-item">
                        <div className="project-image">
                          <img src={project.mainPicture[0].url} alt="" />
                        </div>
                        <a href={project.slug}>
                          <div className="project-overlay"></div>
                        </a>
                        <div className="project-info">
                          <span className="project-name">{project.name}</span>
                          <span className="project-date">{project.year}</span>
                        </div>
                      </div>
                    );
                  }
                  return (

                    <div data-size="large" className="project-item">
                      <div className="project-image">
                        <img src={project.mainPicture[0].url} alt="" />
                      </div>
                      <a href={project.slug}>
                        <div className="project-overlay"></div>
                      </a>
                      <div className="project-info">
                        <span className="project-name">{project.name}</span>
                        <span className="project-date">{project.year}</span>
                      </div>
                    </div>
                  );
                }) : ""
              }
            </div>
          </div>


        </div>
      </div>
    </>
  );
}

export default PortfolioComponent;