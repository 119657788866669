import useScript from "../../hooks/useScript"
import { useEffect, useState } from "react";
import { request, gql } from 'graphql-request'
import ReactHtmlParser from 'react-html-parser';

const query = gql`
  {
  pageServicess {
    description
    pageDescription
    pageTitle
    serviceMainImage {
      url
    }
    serviceItems {
      serviceTitle
      serviceDescription
      serviceImage {
        url
      }
    }
  }
}`


const ServicesComponent = () => {

  const [servicePage, setServicePage] = useState<any[]>([]);
  const [loaded, setLoaded] = useState(Boolean);

  useEffect(() => {
    async function getData() {
      const res = await request('https://api-eu-west-2.hygraph.com/v2/clcsvyi131soh01ur2dq19b6b/master', query);
      setServicePage(res.pageServicess);
      setLoaded(true);
    }
    getData();
  }, []);


  return (

    <>

      <div id="services-1"
        className="container-fluid container-section relative container-section page-margin-top padding-page ">
        <div id="services-1-scroll" className="row fit-content row-no-margin block">
          <div className="row row-no-margin">
            <h2 className="section-title">
              <span className="section-subtitle"><a href="#">{
                loaded ? servicePage[0].pageTitle : ""
              }</a></span>
            </h2>
          </div>
          <div className="row row-no-margin" >
            <div className="section-description page-normal-text">
              <p>
                {
                  loaded ? ReactHtmlParser(servicePage[0].pageDescription) : ""
                }
              </p>
              <p>
                {
                  loaded ? ReactHtmlParser(servicePage[0].description) : ""
                }
              </p>
            </div>
            <div className="section-description page-normal-text relative">

              {
                loaded ? <img className="page-sub-hero" src={servicePage[0].serviceMainImage.url} alt="" /> : ""
              }

            </div>
          </div>
        </div>
      </div>

      <div id="services-2"
        className="container-fluid container-section relative container-section service-padding-top padding-services-left service-block">
        <div id="services-2-scroll" className="row fit-content row-no-margin block">
          <div className="row row-no-margin">
            <div className="service-container">
              <div className="service-image">
                <div className="panes panes-tab">
                  <div id="tab-pane1" className="pane pane1 no-padding panes-before"></div>
                  <div id="tab-pane2" className="pane pane2 no-padding panes-before"></div>
                  <div id="tab-pane3" className="pane pane3 no-padding panes-before"></div>
                </div>

                {
                  loaded ? <img src={servicePage[0].serviceItems[0].serviceImage.url} alt="" /> : ""
                }


              </div>
              <div className="service-description service-description-right">

                <p className="service-title"><strong>{loaded ? servicePage[0].serviceItems[0].serviceTitle : ""}</strong></p>
                <p className="service-text">{loaded ? ReactHtmlParser(servicePage[0].serviceItems[0].serviceDescription) : ""}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="services-3"
        className="container-fluid container-section relative container-section service-padding-top padding-services-right service-block">
        <div id="services-3-scroll" className="row fit-content row-no-margin block">
          <div className="row row-no-margin">
            <div className="service-container">
              <div className="service-description service-description-left">
                <p className="service-title"><strong>{loaded ? servicePage[0].serviceItems[1].serviceTitle : ""}</strong></p>
                <p className="service-text">{loaded ? ReactHtmlParser(servicePage[0].serviceItems[1].serviceDescription) : ""}</p>
              </div>
              <div className="service-image">
                <div className="panes panes-tab panes-right">
                  <div id="tab-pane1-right" className="pane pane1 no-padding panes-before pane-right"></div>
                  <div id="tab-pane2-right" className="pane pane2 no-padding panes-before pane-right"></div>
                  <div id="tab-pane3-right" className="pane pane3 no-padding panes-before pane-right"></div>
                </div>
                {
                  loaded ? <img src={servicePage[0].serviceItems[1].serviceImage.url} alt="" /> : ""
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="services-4"
        className="container-fluid container-section relative container-section service-margin-top padding-services-left background-color service-block">
        <div id="services-2-scroll" className="row fit-content row-no-margin block">
          <div className="row row-no-margin">
            <div className="service-container">
              <div className="service-image"><div className="panes panes-tab">
                <div id="tab-pane1" className="pane pane1 no-padding panes-before"></div>
                <div id="tab-pane2" className="pane pane2 no-padding panes-before"></div>
                <div id="tab-pane3" className="pane pane3 no-padding panes-before"></div>
              </div>
                {
                  loaded ? <img src={servicePage[0].serviceItems[2].serviceImage.url} alt="" /> : ""
                }</div>
              <div className="service-description service-description-right">
                <p className="service-title"><strong>{loaded ? servicePage[0].serviceItems[2].serviceTitle : ""}</strong></p>
                <p className="service-text">{loaded ? ReactHtmlParser(servicePage[0].serviceItems[2].serviceDescription) : ""}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="services-5"
        className="container-fluid container-section relative container-section service-padding-top padding-services-right background-color service-block">
        <div id="services-5-scroll" className="row fit-content row-no-margin block">
          <div className="row row-no-margin">
            <div className="service-container">
              <div className="service-description service-description-left">
                <p className="service-title"><strong>{loaded ? servicePage[0].serviceItems[3].serviceTitle : ""}</strong></p>
                <p className="service-text">{loaded ? ReactHtmlParser(servicePage[0].serviceItems[3].serviceDescription) : ""}</p>
              </div>
              <div className="service-image"><div className="panes panes-tab panes-right">
                <div id="tab-pane1-right" className="pane pane1 no-padding panes-before pane-right"></div>
                <div id="tab-pane2-right" className="pane pane2 no-padding panes-before pane-right"></div>
                <div id="tab-pane3-right" className="pane pane3 no-padding panes-before pane-right"></div>
              </div>{
                  loaded ? <img src={servicePage[0].serviceItems[3].serviceImage.url} alt="" /> : ""
                }</div>
            </div>
          </div>
        </div>
      </div>
      <div id="services-6"
        className="container-fluid container-section relative container-section service-padding-top padding-services-left service-block">
        <div id="services-6-scroll" className="row fit-content row-no-margin block">
          <div className="row row-no-margin">
            <div className="service-container">
              <div className="service-image"><div className="panes panes-tab">
                <div id="tab-pane1" className="pane pane1 no-padding panes-before"></div>
                <div id="tab-pane2" className="pane pane2 no-padding panes-before"></div>
                <div id="tab-pane3" className="pane pane3 no-padding panes-before"></div>
              </div>{
                  loaded ? <img src={servicePage[0].serviceItems[4].serviceImage.url} alt="" /> : ""
                }</div>
              <div className="service-description service-description-right">
                <p className="service-title"><strong>{loaded ? servicePage[0].serviceItems[4].serviceTitle : ""}</strong></p>
                <p className="service-text">{loaded ? ReactHtmlParser(servicePage[0].serviceItems[4].serviceDescription) : ""}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="services-7"
        className="container-fluid container-section relative container-section service-padding-top padding-services-right service-block">
        <div id="services-7-scroll" className="row fit-content row-no-margin block">
          <div className="row row-no-margin">
            <div className="service-container">
              <div className="service-description service-description-left">
                <p className="service-title"><strong>{loaded ? servicePage[0].serviceItems[5].serviceTitle : ""}</strong></p>
                <p className="service-text">{loaded ? ReactHtmlParser(servicePage[0].serviceItems[5].serviceDescription) : ""}</p>
              </div>
              <div className="service-image"><div className="panes panes-tab panes-right">
                <div id="tab-pane1-right" className="pane pane1 no-padding panes-before pane-right"></div>
                <div id="tab-pane2-right" className="pane pane2 no-padding panes-before pane-right"></div>
                <div id="tab-pane3-right" className="pane pane3 no-padding panes-before pane-right"></div>
              </div>{
                  loaded ? <img src={servicePage[0].serviceItems[5].serviceImage.url} alt="" /> : ""
                }</div>
            </div>
          </div>
        </div>
      </div>
      <div id="services-8"
        className="container-fluid container-section relative container-section service-margin-top padding-services-left background-color service-block">
        <div id="services-8-scroll" className="row fit-content row-no-margin block">
          <div className="row row-no-margin">
            <div className="service-container">
              <div className="service-image"><div className="panes panes-tab">
                <div id="tab-pane1" className="pane pane1 no-padding panes-before"></div>
                <div id="tab-pane2" className="pane pane2 no-padding panes-before"></div>
                <div id="tab-pane3" className="pane pane3 no-padding panes-before"></div>
              </div>{
                  loaded ? <img src={servicePage[0].serviceItems[6].serviceImage.url} alt="" /> : ""
                }</div>
              <div className="service-description service-description-right">
                <p className="service-title"><strong>{loaded ? servicePage[0].serviceItems[6].serviceTitle : ""}</strong></p>
                <p className="service-text">{loaded ? ReactHtmlParser(servicePage[0].serviceItems[6].serviceDescription) : ""}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="services-9"
        className="container-fluid container-section relative container-section service-padding-top padding-services-right background-color service-block">
        <div id="services-9-scroll" className="row fit-content row-no-margin block">
          <div className="row row-no-margin">
            <div className="service-container">
              <div className="service-description service-description-left">
              <p className="service-title"><strong>{loaded ? servicePage[0].serviceItems[7].serviceTitle : ""}</strong></p>
                <p className="service-text">{loaded ? ReactHtmlParser(servicePage[0].serviceItems[7].serviceDescription) : ""}</p>
              </div>
              <div className="service-image"><div className="panes panes-tab panes-right">
                <div id="tab-pane1-right" className="pane pane1 no-padding panes-before pane-right"></div>
                <div id="tab-pane2-right" className="pane pane2 no-padding panes-before pane-right"></div>
                <div id="tab-pane3-right" className="pane pane3 no-padding panes-before pane-right"></div>
              </div>{
                  loaded ? <img src={servicePage[0].serviceItems[7].serviceImage.url} alt="" /> : ""
                }</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServicesComponent;