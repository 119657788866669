import { MainComponent } from "../main";
import { HeaderComponent } from '../header';
import { useState } from 'react';
import useScript from "../../hooks/useScript";
import { DarkModeSwitch } from 'react-toggle-dark-mode';


const LayoutComponent = () => {

    useScript(
        "js/main.js",
    )



    return (
        <>

            <div className="custom-cursor">
                <div className="custom-cursor-dots">
                    <div className="dot1"></div>
                    <div className="dot2"></div>
                </div>

            </div>
            <HeaderComponent />
            <div id="my-scrollbar">
                <MainComponent />
            </div>
        </>
    );
}

export default LayoutComponent;