const HeaderComponent = () => {
    return (
        <>
            <header id="header" className="header--top">
                <div className="container-header">
                    <div className="brand"><a href="/" aria-current="page"
                        className="router-link-exact-active router-link-active"><img className="header-logo" src="img/logo.png" alt="logo"></img></a>
                    </div>
                    <div className="nav">
                        <ul className="main-nav">
                            <li className="nav__item"><a href="about" className="nav__link" >About</a></li>
                            <li className="nav__item"><a href="services" className="nav__link" >Services</a></li>
                            <li className="nav__item"><a href="aftercare" className="nav__link" >Aftercare</a></li>
                            <li className="nav__item"><a href="gallery" className="nav__link" >Gallery</a></li>
                            <li className="nav__item"><a href="contact" className="nav__link" >Contact</a></li>
                        </ul>
                    </div>
                </div>
            </header>
            <header id="header-mobile">
                <div className="brand"><a href="/" aria-current="page"
                    className="router-link-exact-active router-link-active"><img className="header-logo-mobile" src="img/logo.png" alt="logo"></img></a>
                </div>
                <div className="hamburger-menu" id="hamburger-menu">
                    <div className="menu-bar1"></div>
                    <div className="menu-bar2"></div>
                    <div className="menu-bar3"></div>
                </div>
            </header>
            <div id="menu-mobile" className="mobile-menu">
                    <div className="nav">
                        <ul className="main-nav">
                            <li className="nav__item"><a href="about" className="nav__link" >About</a></li>
                            <li className="nav__item"><a href="services" className="nav__link" >Services</a></li>
                            <li className="nav__item"><a href="aftercare" className="nav__link" >Aftercare</a></li>
                            <li className="nav__item"><a href="gallery" className="nav__link" >Gallery</a></li>
                            <li className="nav__item"><a href="contact" className="nav__link" >Contact</a></li>
                        </ul>
                    </div>
                </div>
        </>
    );
}

export default HeaderComponent;