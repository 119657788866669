import useScript from "../../hooks/useScript"
import { useEffect, useState } from "react";
import { request, gql } from 'graphql-request'
import ReactHtmlParser from 'react-html-parser';


const query = gql`
  {
  abouts {
    aboutPictures {
      url(
      transformation: {
        image: { resize: { width: 1000, fit: clip } }
      }
     )
    }
    aboutTitle
    leftDescription
    rightDescription
    leftBottom
  }
}
`

const AboutComponent = () => {

  const [aboutPage, setaboutPage] = useState<any[]>([]);
  const [loaded, setLoaded] = useState(Boolean);

  useEffect(() => {
    async function getData() {
      const res = await request('https://api-eu-west-2.hygraph.com/v2/clcsvyi131soh01ur2dq19b6b/master', query);
      setaboutPage(res.abouts);
      setLoaded(true);
    }
    getData();
  }, []);

  return (
    <>
      <div id="about-1" className="container-fluid container-section relative container-section page-margin-top padding-page">
        <div id="about-1-scroll" className="row fit-content row-no-margin block">
          <div className="row row-no-margin">
            <h2 className="section-title">
              <span className="section-maintitle">{loaded ? aboutPage[0].aboutTitle : ""}</span>
            </h2>
          </div>
          <div className="row row-no-margin">
            <div className="section-description page-normal-text">
              <p>{loaded ? ReactHtmlParser(aboutPage[0].leftDescription) : ""}</p>
              <p>{loaded ? ReactHtmlParser(aboutPage[0].leftBottom) : ""}
              </p>
            </div>
            <div className="section-description page-normal-text">
              <p>{loaded ? ReactHtmlParser(aboutPage[0].rightDescription) : ""}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div id="about-2" className="container-fluid container-section relative container-section padding-page">
        <div id="about-2-scroll" className="row fit-content row-no-margin block">
          <div className="row row-no-margin">
            <div className="picture-row">
              {
                loaded ? aboutPage[0].aboutPictures.map((image: any) => {
                  return (
                    <img src={image.url}></img>
                  )
                }) : ""
              }
            </div>
          </div>
        </div>
      </div>

      <div id="about-3" className="container-fluid container-section relative container-section padding-page background-color padding-background">
        <div id="about-3-scroll" className="row fit-content row-no-margin block">
          <div className="row row-no-margin">

          </div>
          <div className="row row-no-margin">

            <div className="section-description page-normal-text no-bottom-margin">
              <h2 className="section-title">
                <span className="section-maintitle">Location</span>
              </h2>
              <p>Nova Design Associates Ltd<br />Unit 18,  Mitre Bridge Ind. Park, Mitre Way <br />London W10 6AU | UK
              </p>
            </div>
            <div className="section-description page-normal-text no-bottom-margin">
              <iframe className="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9929.659260284216!2d-0.2310143!3d51.5239511!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xae1b610c2b438bb9!2sNova%20Designs!5e0!3m2!1sen!2spl!4v1671102483744!5m2!1sen!2spl" loading="lazy"></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutComponent;