import useScript from "../../hooks/useScript"
import { useEffect, useState } from "react";
import { request, gql } from 'graphql-request'
import ReactHtmlParser from 'react-html-parser';

const query = gql`
  {homes {
    section1Title
    section1Subtitle
    section1TitleUnderlined
    section3TitleEnding
    section3TitleUnderlined
    portfolioSectionTitleUnderlined
    portfolioSectionTitle
    headerTitle
    headerSubtitle
    quotess {
      quoteText
    }
    section1Pictures {
      url(
      transformation: {
        image: { resize: { width: 1000, fit: clip } }
      }
     )
    }
    section3Pictures {
      url(
      transformation: {
        image: { resize: { width: 1000, fit: clip } }
      }
     )
    }
    id
    portfolioSectionProjects {
      mainPicture {
        url(
      transformation: {
        image: { resize: { width: 1000, fit: clip } }
      }
     )
      }
      slug
      name
      year
    }
  }}`

const HomeComponent = () => {

    const [homepage, setHomepage] = useState<any[]>([]);
    const [loaded, setLoaded] = useState(Boolean);

    useEffect(() => {
        async function getData() {
            const res = await request('https://api-eu-west-2.hygraph.com/v2/clcsvyi131soh01ur2dq19b6b/master', query);
            setHomepage(res.homes);
            setLoaded(true);
        }
        getData();
    }, []);


    useScript(
        "js/home.js",
    )


    return (
        <>
            <div className="container-fluid max-height no-padding relative section-margin-bottom">
                <div className="row max-height">
                    <div className="container-fluid max-height no-padding">
                        <div className="panes">
                            <div id="pane1" className="pane pane1 no-padding panes-before"></div>
                            <div id="pane2" className="pane pane2 no-padding panes-before"></div>
                            <div id="pane3" className="pane pane3 no-padding panes-before"></div>
                        </div>
                        <div className="background-hero">
                            <div className="hero-overlay"></div>
                        </div>

                        <div id="hero" className="hero">
                            <h1 className="hero-title" >
                                {
                                    loaded ? homepage[0].headerTitle : ""
                                }
                            </h1>
                            <div className="hero_subtitle" >
                                {
                                    loaded ? ReactHtmlParser(homepage[0].headerSubtitle) : ""
                                }
                            </div>
                        </div>
                    </div>
                    <div className="hero_discover">Discover</div>
                    <div className="hero_discover_line"></div>
                </div>
            </div>

            <div id="homepage-1" className="container-fluid container-section max-height relative">
                <div id="homepage-1-scroll" className="row max-height row-no-margin block">
                    <div className="row row-no-margin">
                        <h2 className="section-title">
                            <span className="section-maintitle">{
                                loaded ? homepage[0].section1Title : ""
                            }</span>
                            <span className="section-subtitle"><a href="about">{
                                loaded ? homepage[0].section1TitleUnderlined : ""
                            }</a></span>
                        </h2>
                    </div>
                    <div className="row row-no-margin">
                        <div className="section-description">
                            
                        {
                                    loaded ? ReactHtmlParser(homepage[0].section1Subtitle) : ""
                                }
                        </div>
                    </div>
                    <div className="row row-no-margin">
                        <div className="carousel">

                            {
                                loaded ? homepage[0].section1Pictures.map((image: any) => {
                                    return (
                                        <img src={image.url}></img>
                                    )
                                }) : ""
                            }
                        </div>
                    </div>

                </div>
            </div>

            <div id="homepage-2" className="container-fluid container-section relative container-section-center">
                <div id="homepage-2-scroll" className="row fit-content row-no-margin block">
                    <div className="row row-no-margin">
                        <h2 className="section-title">
                            <span className="section-maintitle">{
                                loaded ? homepage[0].portfolioSectionTitle : ""
                            }</span>
                            <span className="section-subtitle"><a href="gallery">{
                                loaded ? homepage[0].portfolioSectionTitleUnderlined : ""
                            }</a></span>
                        </h2>
                    </div>
                    <div className="row row-no-margin">
                        <div className="projects-grid">

                            {
                                loaded ? homepage[0].portfolioSectionProjects.map((project: any, index: any) => {
                                    if (index % 2 == 1) {
                                        return (
                                            <div data-size="small" className="project-item">
                                                <div className="project-image">
                                                    <img src={project.mainPicture[0].url} alt="" />
                                                </div>
                                                <a href="#">
                                                    <div className="project-overlay"></div>
                                                </a>
                                                <div className="project-info">
                                                    <span className="project-name">{project.name}</span>
                                                </div>
                                            </div>
                                        );
                                    }
                                    return (

                                        <div data-size="large" className="project-item">
                                            <div className="project-image">
                                                <img src={project.mainPicture[0].url} alt="" />
                                            </div>
                                            <a href="#">
                                                <div className="project-overlay"></div>
                                            </a>
                                            <div className="project-info">
                                                <span className="project-name">{project.name}</span>
                                                <span className="project-date">{project.year}</span>
                                            </div>
                                        </div>
                                    );
                                }) : ""
                            }

                        </div>
                    </div>
                </div>
            </div>

            <div id="homepage-3" className="container-fluid container-section  relative container-section-center-right">
                <div id="homepage-3-scroll" className="row fit-content row-no-margin block">
                    <div className="row row-no-margin">
                        <h2 className="section-title">
                            <span className="section-subtitle inline-block"><a href="services">{
                                loaded ? homepage[0].section3TitleUnderlined : ""
                            }</a></span>{' '}
                            <span className="section-maintitle inline-block">{
                                loaded ? homepage[0].section3TitleEnding : ""
                            }</span>
                        </h2>
                    </div>
                    <div className="row row-no-margin padding-top">
                        <div className="col-sm-4">
                            <div className="quote-container vertical-center">
                                <div className="quote-wrapper">
                                    <div className="quote-item">
                                        <div className="quote-text">
                                            <p>
                                                {
                                                    loaded ? ReactHtmlParser(homepage[0].quotess[0].quoteText) : ""
                                                }</p>
                                        </div>
                                        <div className="quote-author">
                                            {
                                                loaded ? ReactHtmlParser(homepage[0].quotess[1].quoteText) : ""
                                            }</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-1"></div>
                        <div className="col-sm-7">
                            <div id="carousel1" className="carousel carousel-team">
                                {
                                    loaded ? homepage[0].section3Pictures.map((image: any) => {
                                        return (
                                            <img src={image.url}></img>
                                        )
                                    }) : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HomeComponent;