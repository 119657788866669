const FooterPortfolio = () => {
    return (
      <footer className="footer-portfolio-item">
      <a href="contact" className="contact-us outline">Contact Us<span className="contact-arrow"><svg height="11"
            viewBox="0 0 12 11" width="12" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fill-rule="evenodd" stroke="#353d35" transform="translate(1 1)">
              <path d="m0 0h10v10"></path>
              <path d="m10 0-10 10"></path>
            </g>
          </svg></span></a>
      <div className="footer-content">
        <div className="row row-no-margin">
          <div className="address">
            <p>Nova Design Associates Ltd <br/>Unit 18, Mitre Bridge Ind. Park, Mitre Way <br />London W10 6AU | UK</p>
          </div>
          <div className="accredit-container">
            <img src="img/accr/1.png" />
            <img src="img/accr/2.png" />
            <img src="img/accr/3.jpg" />
            <img src="img/accr/5.jpg" />
            <img src="img/accr/6.png" />
            <img src="img/accr/7.jpg" />
            <img src="img/accr/8.png" />
            <img src="img/accr/9.png" />
          </div>
        </div>
        <span className="footer-line"></span>
        <div className="row row-no-margin footer-bottom">
          <div className="copyrights">NOVADESIGN © 2023</div>
          <div className="footer-links">
            <a href="https://www.facebook.com/NovadesignLtdLondon">Facebook</a>
            <a href="#">Instagram</a>
          </div>
        </div>
      </div>
    </footer>
    );
}

export default FooterPortfolio;