const FooterContactComponent = () => {
    return (
        
    <footer id="portfolio-footer">
    
    <div className="footer-content">
      <div className="row row-no-margin">
        <div className="address"><p>Nova Design Associates Ltd <br/>Unit 18, Mitre Bridge Ind. Park, Mitre Way <br/>London W10 6AU | UK</p></div>
        <div className="accredit-container">
          <img src="img/accr/1.png" ></img>
          <img src="img/accr/2.png" ></img>
          <img src="img/accr/3.jpg" ></img>
          <img src="img/accr/5.jpg" ></img>
          <img src="img/accr/6.png" ></img>
          <img src="img/accr/7.jpg" ></img>
          <img src="img/accr/8.png" ></img>
          <img src="img/accr/9.png" ></img>
        </div>
      </div>
      <span className="footer-line"></span>
      <div className="row row-no-margin footer-bottom">
        <div className="copyrights">NOVADESIGN © 2023</div>
        <div className="footer-links">
          <a href="https://www.facebook.com/NovadesignLtdLondon">Facebook</a>
          <a href="#">Instagram</a>
        </div>
      </div>
    </div>
  </footer>
    );
}

export default FooterContactComponent;