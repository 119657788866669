import useScript from "../../hooks/useScript"
import { useEffect, useState } from "react";
import { request, gql } from 'graphql-request'
import ReactHtmlParser from 'react-html-parser';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useParams,
} from "react-router-dom";
import { url } from "inspector";


const query = gql`
query ($slug: String!) {
 projects(where: {slug: $slug}) {
   description
   name
   mainPicture {
     url(
      transformation: {
        image: { resize: { width: 2000, fit: clip } }
      }
     )
   }
   slug
   pictures {
     url(
      transformation: {
        image: { resize: { width: 1000, fit: clip } }
      }
     )
   }
   year
 }
}`


const ProjectComponent = () => {
  const slug = useParams();
  const variables = {
    slug: slug.slug,
  }

  const [project, setProject] = useState<any[]>([]);
  const [loaded, setLoaded] = useState(Boolean);

  useEffect(() => {
    async function getData() {
      const res = await request('https://api-eu-west-2.hygraph.com/v2/clcsvyi131soh01ur2dq19b6b/master', query, variables);
      setProject(res.projects);
      setLoaded(true);
    }
    getData();
  }, []);

  useScript(
    "js/project.js",
  )
  return (
    <>
      <div className="container-fluid max-height no-padding relative section-margin-bottom">
        <div className="row max-height">
          <div className="container-fluid max-height no-padding">
            
            {loaded ? 
              <div className="background-hero" style={{ backgroundImage: `url(${project[0].mainPicture[0].url})` }}>
              </div>
            : ""}
            


          </div>
          <div className="hero_discover">Discover</div>
          <div className="hero_discover_line"></div>
        </div>
      </div>

      <div id="homepage-1" className="container-fluid container-section max-height relative">
        <div id="homepage-1-scroll" className="row max-height row-no-margin block">
          <div className="row row-no-margin">
            <h2 className="section-title">
              <span className="section-maintitle">{loaded ? ReactHtmlParser(project[0].name) : ""}</span>
            </h2>
          </div>
          <div className="row row-no-margin">
            <div className="section-description mobile-description-project">
              <p>{loaded ? ReactHtmlParser(project[0].description) : ""}</p>
            </div>
          </div>
          <div className="row row-no-margin">
            <div className="carousel">
              {
                loaded ? project[0].pictures.map((image: any) => {
                  return (
                    <img src={image.url}></img>
                  )
                }) : ""
              }
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default ProjectComponent;