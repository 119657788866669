import { useEffect, useState } from "react";
import { request, gql } from 'graphql-request'
import ReactHtmlParser from 'react-html-parser';

const query = gql`
  {
  pageAftercares {
    description
    description2
    title
    aftercareItems {
      aftercareTitle
      aftercareDescription
      aftercareImage {
        url
      }
    }
    mainImage {
      url
    }
  }
}`

const AboutComponent = () => {
  const [aftercarePage, setAftercarePage] = useState<any[]>([]);
  const [loaded, setLoaded] = useState(Boolean);

  useEffect(() => {
    async function getData() {
      const res = await request('https://api-eu-west-2.hygraph.com/v2/clcsvyi131soh01ur2dq19b6b/master', query);
      setAftercarePage(res.pageAftercares);
      setLoaded(true);
    }
    getData();
  }, []);


  return (

    <>
      <div id="services-1"
        className="container-fluid container-section relative container-section page-margin-top padding-page ">
        <div id="services-1-scroll" className="row fit-content row-no-margin block">
          <div className="row row-no-margin">
            <h2 className="section-title">
              <span className="section-subtitle"><a href="#">{
                loaded ? aftercarePage[0].title : ""
              }</a></span>
            </h2>
          </div>
          <div className="row row-no-margin" >
            <div className="section-description page-normal-text">
              <p>
                {
                  loaded ? ReactHtmlParser(aftercarePage[0].description) : ""
                }
              </p>
              <p>
                {
                  loaded ? ReactHtmlParser(aftercarePage[0].description2) : ""
                }
              </p>
            </div>

            <div className="section-description page-normal-text relative">
              {
                loaded ? <img className="page-sub-hero page-sub-hero-aftercare" src={aftercarePage[0].mainImage.url} alt="" /> : ""
              }
            </div>
          </div>
        </div>
      </div>
      <div id="services-2"
        className="container-fluid container-section relative container-section service-padding-top padding-services-left service-block">
        <div id="services-2-scroll" className="row fit-content row-no-margin block">
          <div className="row row-no-margin">
            <div className="service-container">
              <div className="service-image">
                <div className="panes panes-tab">
                  <div id="tab-pane1" className="pane pane1 no-padding panes-before"></div>
                  <div id="tab-pane2" className="pane pane2 no-padding panes-before"></div>
                  <div id="tab-pane3" className="pane pane3 no-padding panes-before"></div>
                </div>{
                  loaded ? <img src={aftercarePage[0].aftercareItems[0].aftercareImage.url} alt="" /> : ""
                }
              </div>
              <div className="service-description service-description-right">

                <p className="service-title"><strong>{loaded ? aftercarePage[0].aftercareItems[0].aftercareTitle : ""}</strong></p>
                <p className="service-text">{loaded ? ReactHtmlParser(aftercarePage[0].aftercareItems[0].aftercareDescription) : ""}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="services-3"
        className="container-fluid container-section relative container-section service-padding-top padding-services-right service-block">
        <div id="services-3-scroll" className="row fit-content row-no-margin block">
          <div className="row row-no-margin">
            <div className="service-container">
              <div className="service-description service-description-left">
                <p className="service-title"><strong>{loaded ? aftercarePage[0].aftercareItems[1].aftercareTitle : ""}</strong></p>
                <p className="service-text">{loaded ? ReactHtmlParser(aftercarePage[0].aftercareItems[1].aftercareDescription) : ""}</p>
              </div>
              <div className="service-image">
                <div className="panes panes-tab panes-right">
                  <div id="tab-pane1-right" className="pane pane1 no-padding panes-before pane-right"></div>
                  <div id="tab-pane2-right" className="pane pane2 no-padding panes-before pane-right"></div>
                  <div id="tab-pane3-right" className="pane pane3 no-padding panes-before pane-right"></div>
                </div>
                {
                  loaded ? <img src={aftercarePage[0].aftercareItems[1].aftercareImage.url} alt="" /> : ""
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="services-4"
        className="container-fluid container-section relative container-section service-margin-top padding-services-left background-color service-block">
        <div id="services-2-scroll" className="row fit-content row-no-margin block">
          <div className="row row-no-margin">
            <div className="service-container">
              <div className="service-image">
                <div className="panes panes-tab">
                  <div id="tab-pane1" className="pane pane1 no-padding panes-before"></div>
                  <div id="tab-pane2" className="pane pane2 no-padding panes-before"></div>
                  <div id="tab-pane3" className="pane pane3 no-padding panes-before"></div>
                </div>
                {
                  loaded ? <img src={aftercarePage[0].aftercareItems[2].aftercareImage.url} alt="" /> : ""
                }
              </div>
              <div className="service-description service-description-right">
                <p className="service-title"><strong>{loaded ? aftercarePage[0].aftercareItems[2].aftercareTitle : ""}</strong></p>
                <p className="service-text">{loaded ? ReactHtmlParser(aftercarePage[0].aftercareItems[2].aftercareDescription) : ""}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="services-5"
        className="container-fluid container-section relative container-section service-padding-top padding-services-right background-color service-block">
        <div id="services-5-scroll" className="row fit-content row-no-margin block">
          <div className="row row-no-margin">
            <div className="service-container">
              <div className="service-description service-description-left">
                <p className="service-title"><strong>{loaded ? aftercarePage[0].aftercareItems[3].aftercareTitle : ""}</strong></p>
                <p className="service-text">{loaded ? ReactHtmlParser(aftercarePage[0].aftercareItems[3].aftercareDescription) : ""}</p>
              </div>
              <div className="service-image">
                <div className="panes panes-tab panes-right">
                  <div id="tab-pane1-right" className="pane pane1 no-padding panes-before pane-right"></div>
                  <div id="tab-pane2-right" className="pane pane2 no-padding panes-before pane-right"></div>
                  <div id="tab-pane3-right" className="pane pane3 no-padding panes-before pane-right"></div>
                </div>
                {
                  loaded ? <img src={aftercarePage[0].aftercareItems[3].aftercareImage.url} alt="" /> : ""
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="services-6"
      className="container-fluid container-section relative container-section service-padding-top padding-services-left service-block">
      <div id="services-6-scroll" className="row fit-content row-no-margin block">
        <div className="row row-no-margin">
          <div className="service-container">
            <div className="service-image">
              <div className="panes panes-tab">
                <div id="tab-pane1" className="pane pane1 no-padding panes-before"></div>
                <div id="tab-pane2" className="pane pane2 no-padding panes-before"></div>
                <div id="tab-pane3" className="pane pane3 no-padding panes-before"></div>
              </div>
                {
                  loaded ? <img src={aftercarePage[0].aftercareItems[4].aftercareImage.url} alt="" /> : ""
                }
            </div>
            <div className="service-description service-description-right">
                <p className="service-title"><strong>{loaded ? aftercarePage[0].aftercareItems[4].aftercareTitle : ""}</strong></p>
                <p className="service-text">{loaded ? ReactHtmlParser(aftercarePage[0].aftercareItems[4].aftercareDescription) : ""}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default AboutComponent;